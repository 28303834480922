import {
	DragDropContext,
	Droppable,
	DropResult,
	ResponderProvided,
} from 'react-beautiful-dnd';
import { List, ListItem, useTheme } from '@mui/material';
import { IconButton } from 'features';
import ItemList from './ItemList';

export interface DraggableListProps<T> {
	data: T[];
	getItemId: (item: T) => string;
	getListItemContent: (item: T) => JSX.Element | string;
	onDeleteClick: (index: number) => void;
	onAddItem?: () => void;
	onItemDrop: (result: DropResult, provided: ResponderProvided) => void;
}

export const DraggableList = <T,>({
	data,
	getItemId,
	getListItemContent,
	onDeleteClick,
	onAddItem,
	onItemDrop,
}: DraggableListProps<T>) => {
	const theme = useTheme();

	return (
		<DragDropContext onDragEnd={onItemDrop}>
			<Droppable droppableId="list">
				{(provided) => (
					<List
						{...provided.droppableProps}
						ref={provided.innerRef}
						sx={{ width: '100%', p: 0 }}
					>
						<ItemList
							data={data}
							getItemId={getItemId}
							getListItemContent={getListItemContent}
							onDeleteClick={onDeleteClick}
						/>
						{provided.placeholder}
						{onAddItem && (
							<ListItem
								sx={{
									border: `1px solid ${theme.colors.alpha.grey[3]}`,
									borderRadius: '10px',
									width: '100%',
									py: 0,
									my: 2,
								}}
							>
								<IconButton icon={'UilPlus'} onClick={onAddItem} />
							</ListItem>
						)}
					</List>
				)}
			</Droppable>
		</DragDropContext>
	);
};
