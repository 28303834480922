export interface User {
	id: string;
	firebaseUid: string;
	name: string;
	email: string;
	role: string;
	phoneNumber: string;
	isActive: boolean;
	responsibility: string[];
}

export enum UserRoles {
	Admin = 'Admin',
	User = 'User',
}

export enum Platform {
	TMS = 0,
	CA = 1,
	ForU = 2,
}

export enum Responsibility {
	NONE = 0,
	TA = 1,
	CA = 2,
}

export const getResponsibilityDescription = (
	responsibility: Responsibility,
	shortDescription: boolean
) => {
	switch (responsibility) {
		case Responsibility.NONE:
			return 'None';
		case Responsibility.TA:
			return shortDescription ? 'TA' : 'Technical Advisor';
		case Responsibility.CA:
			return shortDescription ? 'CA' : 'Crop Advisor';
	}
};

interface UserRequestBody {
	name: string;
	email: string;
	role: string;
	phoneNumber: string;
}

export interface CreateUserRequestBody extends UserRequestBody {
	platform: [Platform];
	responsibilities: [Responsibility];
}

export interface UpdateUserRequestBody extends UserRequestBody {
	id: string;
	responsibility: Responsibility;
	isResponsibility: boolean;
}

export interface InviteUserRequestBody {
	userId: string;
	redirectUrl: string;
}

export interface ToggleUserBody {
	id: string;
	enabled: boolean;
}

export interface VerifyUserEmailBody {
	firebaseId: string;
	verified: boolean;
}

export interface ResetPasswordRequestBody {
	email: string;
	redirectUrl: string;
}

export interface BcCandidate {
	firebaseUid: string;
	bcUniqueId: string;
	name: string;
	emailAddress: string;
	status: string;
	invited: boolean;
	role: string;
	isActive: boolean;
}

export type InviteBcUser = {
	bcUniqueId: string;
	name: string;
	emailAddress: string;
	redirectUrl: string;
};

export type InviteCropAdvisor = {
	bcSalesPersonCode: string;
	name: string;
	email: string;
	phoneNumber?: string;
	redirectUrl: string;
};

export type ResendInviteCropAdvisor = {
	email: string;
	redirectUrl: string;
};