import { useContext } from 'react';
import {
	Drawer,
	List,
	ListItemIcon,
	ListItemText,
	ListSubheader,
	useTheme,
	ListItemButton,
	Collapse,
} from '@mui/material';
import { Box } from '@mui/system';
import { DrawerWrapper, ListDropdownBar } from './Sidebar.styles';
import { Icon } from 'features';
import { NavLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import TmsLogo from 'src/assets/img/tms-logo.svg';
import PoweredByIG from 'src/assets/img/powered-by-ig.png';

export const Sidebar = () => {
	const theme = useTheme();
	const titles = useContext(SidebarContext);

	return (
		<div style={{ display: 'flex' }}>
			<Drawer
				variant='permanent'
				sx={{
					'& .MuiDrawer-paper': {
						width: theme.sidebar.width,
						backgroundColor: theme.colors.primary.main,
					},
				}}>
				<DrawerWrapper>
					<List
						component={'nav'}
						disablePadding
						style={{ width: '100%' }}
						subheader={
							<ListSubheader
								component='div'
								id='logo-sign'
								sx={{
									bgcolor: theme.colors.primary.main,
									padding: 0,
								}}>
								<img src={TmsLogo} alt='Logo' />
							</ListSubheader>
						}>
						{titles.titles &&
							titles.titles.map((item, index) => {
								if (item.children) {
									return (
										<Box key={index} paddingTop={'20px'}>
											<ListItemButton
												disableTouchRipple
												onClick={() => titles.openTitleCollapse(item.title)}
												sx={{
													padding: '6px 6px',
													gap: '8px',
													marginRight: '10px',
													color: theme.palette.common.white,
													'&:hover': {
														backgroundColor: 'transparent',
													},
												}}>
												<ListItemIcon sx={{ pl: 1 }}>
													<Icon icon={item.icon} />
												</ListItemIcon>
												<ListItemText
													primaryTypographyProps={{
														color: theme.colors.alpha.white[100],
													}}
													primary={item.title}
												/>
												<Icon
													icon={item.open ? 'UilAngleUp' : 'UilAngleDown'}
													sx={{ color: theme.palette.common.white }}
												/>
											</ListItemButton>
											<Collapse in={item.open} timeout='auto'>
												<List
													component='div'
													disablePadding
													sx={{
														color: theme.palette.common.white,
														pt: '21px',
													}}>
													{item.children?.map((child, index) => {
														return (
															<Box
																key={index}
																display='flex'
																flexDirection={'row'}
																pl={1}
																pb='4px'>
																<ListDropdownBar
																	flexItem
																	orientation='vertical'
																/>
																<NavLink
																	to={child.path}
																	style={({ isActive }) => {
																		return {
																			background: isActive
																				? theme.sidebar.menuItemColor
																				: '',
																			textDecoration: 'none',
																			borderRadius: '15px',
																			my: isActive ? '5px' : '0',
																			'& .MuiListItemButtonRoot': {
																				marginBottom: isActive ? '10px' : '',
																			},
																		};
																	}}>
																	<ListItemButton
																		sx={{
																			gap: '8px',
																			marginRight: '10px',
																			width: '100%',
																			paddingRight: '5px',
																		}}
																		disableTouchRipple>
																		<ListItemIcon>
																			<Icon icon={child.icon} />
																		</ListItemIcon>
																		<ListItemText
																			primaryTypographyProps={{
																				color: theme.colors.alpha.white[100],
																			}}
																			primary={child.title}
																		/>
																	</ListItemButton>
																</NavLink>
															</Box>
														);
													})}
												</List>
											</Collapse>
										</Box>
									);
								} else {
									return (
										<Box key={index} paddingTop={'20px'}>
											<NavLink
												to={item.path}
												style={({ isActive }) => {
													return {
														background: isActive
															? theme.sidebar.menuItemColor
															: '',
														textDecoration: 'none',
														borderRadius: '15px',
														marginBottom: isActive ? '5px' : '0',
														'& .MuiListItemButtonRoot': {
															marginBottom: isActive ? '10px' : '',
														},
													};
												}}>
												<ListItemButton
													disableTouchRipple
													sx={{
														padding: '6px 6px',
														gap: '8px',
														margin: '0px 0px 5px 0px',
													}}>
													<ListItemIcon sx={{ pl: 1 }}>
														<Icon icon={item.icon} />
													</ListItemIcon>
													<ListItemText
														primaryTypographyProps={{
															color: theme.colors.alpha.white[100],
														}}
														primary={item.title}
													/>
												</ListItemButton>
											</NavLink>
										</Box>
									);
								}
							})}
					</List>
					<Box display={'flex'} justifyContent={'center'}>
						<img src={PoweredByIG} alt='Powered by InteliGro' width={'60%'} />
					</Box>
				</DrawerWrapper>
			</Drawer>
		</div>
	);
};
