import {
	AuthError,
	browserLocalPersistence,
	getAuth,
	signOut,
	setPersistence,
	signInWithCustomToken,
	onAuthStateChanged,
	User,
} from 'firebase/auth';
import React, { createContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'src/hooks/hooks';
import { IUserSlice } from 'src/store/user/userSlice.contracts';
import { AuthContextInterface } from '../../context/AuthContextInterface';
import { persistAuth } from '../../utils/persistAuth';
import store from 'src/store/store';
import { app } from 'src/services/firebase/firebase';
import { UserRoles } from 'src/services/types/user.types';
import { getErrorMessage } from 'src/helpers/authHelpers';
import { userApi } from 'src/services/endpoints/usersApi';
import { removeAuthStorage } from 'src/services/firebase/setAuthStorage';
import { pnApi } from 'src/services/pnBaseApi';

export const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence);

export const FirebaseAuthContext = createContext<AuthContextInterface>({
	user: null,
	loading: false,
	error: undefined,
	getTokens: () => new Promise((resolve) => null),
	login: async (token: any) => new Promise((resolve) => null),
	logout: () => new Promise((resolve) => {}),
	refreshToken: () => new Promise((resolve) => {}),
	getUser: () => new Promise((resolve) => {}),
	emailVerified: false,
	authHook: undefined,
});

interface AuthProviderProps {
	children: React.ReactNode;
}

export const FirebaseAuthProvider: React.FC<AuthProviderProps> = ({
	children,
}) => {
	const [user, loading, error] = useAuthState(auth);
	const dispatch = useAppDispatch();

	const deleteCookie = () => localStorage.removeItem('idToken');

	const login = async (token: string) => {
		const tokenUrl = process.env.REACT_APP_VERIFY_TOKEN_URL as string;
		try {
			// logout to clear any current user session
			logoutFirebase(false);

			// take id token from cookie, send to TMS backend for verification
			// and get a custom token as response
			const tokenResponse = await fetch(tokenUrl + token, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			});
			const customToken = await tokenResponse.json();

			// sign into firebase using custom token
			const userCredential = await signInWithCustomToken(
				auth,
				customToken.data
			);

			// get id token from userCredential
			const accessToken = await userCredential.user.getIdToken();

			// fetch user from TMS backend using new id token
			const userResponse = await store.dispatch(
				userApi.endpoints.getCurrentUser.initiate(accessToken)
			);

			if (userResponse.isError || !userResponse.data) {
				logout();
				throw Error(`User with email ${userCredential.user.email} not found`);
			}

			const userAuth: IUserSlice = {
				name: userResponse.data?.data?.name ?? userCredential.user.displayName,
				firebaseUser: userCredential.user,
				status: 'authenticated',
				accessToken: accessToken,
				refreshToken: userCredential.user.refreshToken,
				userType:
					UserRoles[userResponse.data?.data?.role as keyof typeof UserRoles],
				error: null,
			};

			deleteCookie();

			// persist user in local storage
			dispatch(persistAuth({ userAuth }));
		} catch (error: any) {
			deleteCookie();
			logout();
		}
	};

	const logout = async () => {
		try {
			logoutFirebase();
		} catch (error: any) {
			handleError(error);
		}
	};

	const refreshToken = async () => {
		try {
			const res = await refreshFirebaseToken();
		} catch (error: any) {
			handleError(error);
		}
	};

	const getTokens = async () => {
		const currentUser = auth.currentUser;
		try {
			return {
				accessToken: (await currentUser?.getIdToken(true)) ?? null,
				refreshToken: currentUser?.refreshToken ?? null,
			};
		} catch (error: any) {
			handleError(error);
			return null;
		}
	};

	const getUser = async (): Promise<User> => {
		return new Promise((resolve, reject) => {
			onAuthStateChanged(auth, (user) => {
				if (user) {
					resolve(user);
				} else {
					reject(null);
				}
			});
		});
	};

	const handleError = (error: AuthError) => {
		toast.error(getErrorMessage(error.code));
		throw Error();
	};

	const emailVerified = auth.currentUser
		? auth.currentUser.emailVerified
		: false;

	const authHook = useAuthState;

	return (
		<FirebaseAuthContext.Provider
			value={{
				user,
				loading,
				error,
				getTokens,
				login,
				logout,
				refreshToken,
				getUser,
				emailVerified,
				authHook,
			}}
		>
			{children}
		</FirebaseAuthContext.Provider>
	);
};

export const refreshFirebaseToken = async () => {
	try {
		const currentUser = auth.currentUser;
		if (!currentUser) {
			throw new Error('Cannot get new token');
		}
		return {
			accessToken: await currentUser?.getIdToken(true),
			refreshToken: currentUser?.refreshToken,
		};
	} catch (error) {
		return {
			refreshToken: null,
			accessToken: null,
		};
	}
};

export const logoutFirebase = async (redirect?: boolean) => {
	// sign out of firebase
	await signOut(auth);

	// clear rtk cache
	store.dispatch(pnApi.util.resetApiState());

	// remove user from local storage
	removeAuthStorage();

	if (redirect ?? true) {
		// redirect to login or verification page on intelekt-auth
		window.location.href = process.env.REACT_APP_INTELIGRO_SSO_URL as string;
	}
};
