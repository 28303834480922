export const viewFileInNewTab = (blob: Blob): void => {
  const url = window.URL.createObjectURL(blob);
  window.open(url, '_blank');
};

export const downloadFile = (blob: Blob, filename: string): void => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();
  window.URL.revokeObjectURL(url);
};

export const downloadFileFromExternalUrl = async (externalUrl: string, filename: string) => {
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = (event) => {
    const blob = xhr.response;
    downloadFile(blob, filename);
  };
  xhr.open('GET', externalUrl);
  xhr.send();
};

export const blobToFile = (blob: Blob, fileName: string): File => {
  const b: any = blob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;
  //Cast to a File() type
  return blob as File;
}