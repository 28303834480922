import { CustomPdfViewer } from 'features/src/organisms/CustomPdfViewer/CustomPdfViewer';
import { useLocation } from 'react-router-dom';

type PdfViewerState = {
	source: string;
	enableDownload: boolean;
};

const ViewDocument = () => {
	const { state } = useLocation();
	const { source, enableDownload } = state as PdfViewerState;

	return <CustomPdfViewer source={source} enableDownload={enableDownload} />;
};

export default ViewDocument;
